import {Component, OnInit, Input, Optional, Inject, Output, EventEmitter, HostListener} from '@angular/core';
import { ImageViewerConfig, CustomEvent } from './image-viewer-config.model';

const /** @type {?} */ DEFAULT_CONFIG: ImageViewerConfig = {
  btnClass: 'default',
  zoomFactor: 0.1,
  containerBackgroundColor: '#ccc',
  wheelZoom: false,
  allowFullscreen: true,
  allowKeyboardNavigation: true,
  btnShow: {
    zoomIn: true,
    zoomOut: true,
    rotateClockwise: true,
    rotateCounterClockwise: true,
    next: true,
    prev: true
  },
  btnIcons: {
    zoomIn: 'fa fa-plus',
    zoomOut: 'fa fa-minus',
    rotateClockwise: 'fa fa-repeat',
    rotateCounterClockwise: 'fa fa-undo',
    next: 'fa fa-arrow-right',
    prev: 'fa fa-arrow-left',
    fullscreen: 'fa fa-arrows-alt',
  }
};
export class ImageViewerComponent implements OnInit {

  
  src: string[];

  
  index = 0;

  
  config: ImageViewerConfig;

  
  indexChange: EventEmitter<number> = new EventEmitter();

  
  configChange: EventEmitter<ImageViewerConfig> = new EventEmitter();

  
  customEvent: EventEmitter<CustomEvent> = new EventEmitter();
public style = { transform: '', msTransform: '', oTransform: '', webkitTransform: '' };
public fullscreen = false;
public loading = true;
private scale = 1;
private rotation = 0;
private translateX = 0;
private translateY = 0;
private prevX: number;
private prevY: number;
private hovered = false;
/**
 * @param {?} moduleConfig
 */
constructor(
public moduleConfig: ImageViewerConfig) { }
/**
 * @return {?}
 */
ngOnInit() {
    const /** @type {?} */ merged = this.mergeConfig(DEFAULT_CONFIG, this.moduleConfig);
    this.config = this.mergeConfig(merged, this.config);
    this.triggerConfigBinding();
  }
/**
 * @param {?} event
 * @return {?}
 */
nextImage(event) {
    if (this.canNavigate(event) && this.index < this.src.length - 1) {
      this.loading = true;
      this.index++;
      this.triggerIndexBinding();
      this.reset();
    }
  }
/**
 * @param {?} event
 * @return {?}
 */
prevImage(event) {
    if (this.canNavigate(event) && this.index > 0) {
      this.loading = true;
      this.index--;
      this.triggerIndexBinding();
      this.reset();
    }
  }
/**
 * @return {?}
 */
zoomIn() {
    this.scale *= (1 + this.config.zoomFactor);
    this.updateStyle();
  }
/**
 * @return {?}
 */
zoomOut() {
    if (this.scale > this.config.zoomFactor) {
      this.scale /= (1 + this.config.zoomFactor);
    }
    this.updateStyle();
  }
/**
 * @param {?} evt
 * @return {?}
 */
scrollZoom(evt) {
    if (this.config.wheelZoom) {
      evt.deltaY > 0 ? this.zoomOut() : this.zoomIn();
      return false;
    }
  }
/**
 * @return {?}
 */
rotateClockwise() {
    this.rotation += 90;
    this.updateStyle();
  }
/**
 * @return {?}
 */
rotateCounterClockwise() {
    this.rotation -= 90;
    this.updateStyle();
  }
/**
 * @return {?}
 */
onLoad() {
    this.loading = false;
  }
/**
 * @return {?}
 */
onLoadStart() {
    this.loading = true;
  }
/**
 * @param {?} evt
 * @return {?}
 */
onDragOver(evt) {
    this.translateX += (evt.clientX - this.prevX);
    this.translateY += (evt.clientY - this.prevY);
    this.prevX = evt.clientX;
    this.prevY = evt.clientY;
    this.updateStyle();
  }
/**
 * @param {?} evt
 * @return {?}
 */
onDragStart(evt) {
    if (evt.dataTransfer && evt.dataTransfer.setDragImage) {
      evt.dataTransfer.setDragImage(evt.target.nextElementSibling, 0, 0);
    }
    this.prevX = evt.clientX;
    this.prevY = evt.clientY;
  }
/**
 * @return {?}
 */
toggleFullscreen() {
    this.fullscreen = !this.fullscreen;
    if (!this.fullscreen) {
      this.reset();
    }
  }
/**
 * @return {?}
 */
triggerIndexBinding() {
    this.indexChange.emit(this.index);
  }
/**
 * @return {?}
 */
triggerConfigBinding() {
    this.configChange.next(this.config);
  }
/**
 * @param {?} name
 * @param {?} imageIndex
 * @return {?}
 */
fireCustomEvent(name, imageIndex) {
    this.customEvent.emit(new CustomEvent(name, imageIndex));
  }
/**
 * @return {?}
 */
reset() {
    this.scale = 1;
    this.rotation = 0;
    this.translateX = 0;
    this.translateY = 0;
    this.updateStyle();
  }
/**
 * @return {?}
 */
private onMouseOver() {
    this.hovered = true;
  }
/**
 * @return {?}
 */
private onMouseLeave() {
    this.hovered = false;
  }
/**
 * @param {?} event
 * @return {?}
 */
private canNavigate(event: any) {
    return event == null ||  (this.config.allowKeyboardNavigation && this.hovered);
  }
/**
 * @return {?}
 */
private updateStyle() {
    this.style.transform = `translate(${this.translateX}px, ${this.translateY}px) rotate(${this.rotation}deg) scale(${this.scale})`;
    this.style.msTransform = this.style.transform;
    this.style.webkitTransform = this.style.transform;
    this.style.oTransform = this.style.transform;
  }
/**
 * @param {?} defaultValues
 * @param {?} overrideValues
 * @return {?}
 */
private mergeConfig(defaultValues: ImageViewerConfig, overrideValues: ImageViewerConfig): ImageViewerConfig {
    let /** @type {?} */ result: ImageViewerConfig = { ...defaultValues };
    if (overrideValues) {
      result = { ...defaultValues, ...overrideValues };

      if (overrideValues.btnIcons) {
        result.btnIcons = { ...defaultValues.btnIcons, ...overrideValues.btnIcons };
      }
    }
    return result;
  }

static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
  selector: 'ngx-image-viewer',
  template: `
    <div [ngxToggleFullscreen]="fullscreen" class="img-container" [style.backgroundColor]="config.containerBackgroundColor"
         (wheel)="scrollZoom($event)" (dragover)="onDragOver($event)">
      <img [src]="src[index]" [ngStyle]="style" alt="Image not found..." (dragstart)="onDragStart($event)" (load)="onLoad()" (loadstart)="onLoadStart()"/>
      <!-- Div below will be used to hide the 'ghost' image when dragging -->
      <div></div>
      <div class="spinner-container" *ngIf="loading">
        <div class="spinner"></div>
      </div>

      <button type="button" [class]="config.btnClass" *ngIf="config.btnShow.rotateCounterClockwise" (click)="rotateCounterClockwise()">
        <span [class]="config.btnIcons.rotateCounterClockwise"></span>
      </button>
      <button type="button" [class]="config.btnClass" *ngIf="config.btnShow.rotateClockwise" (click)="rotateClockwise()">
        <span [class]="config.btnIcons.rotateClockwise"></span>
      </button>

      <button type="button" [class]="config.btnClass" *ngIf="config.btnShow.zoomOut" (click)="zoomOut()">
        <span [class]="config.btnIcons.zoomOut"></span>
      </button>
      <button type="button" [class]="config.btnClass" *ngIf="config.btnShow.zoomIn" (click)="zoomIn()">
        <span [class]="config.btnIcons.zoomIn"></span>
      </button>

      <button type="button" [class]="config.btnClass" *ngFor="let cBtn of config.customBtns" (click)="fireCustomEvent(cBtn.name, index)">
        <span [class]="cBtn.icon"></span>
      </button>

      <button type="button" id="ngx-fs-btn" [class]="config.btnClass" (click)="toggleFullscreen()" *ngIf="config.allowFullscreen">
        <span [class]="config.btnIcons.fullscreen"></span>
      </button>

      <div class="nav-button-container" *ngIf="src.length > 1">
        <button type="button" [class]="config.btnClass" (click)="prevImage()" [disabled]="index === 0">
          <span [class]="config.btnIcons.prev"></span>
        </button>
        <button type="button" [class]="config.btnClass" (click)="nextImage()" [disabled]="index === src.length - 1">
          <span [class]="config.btnIcons.next"></span>
        </button>
      </div>
    </div>
  `,
  styles: [`
    .img-container {
      height: 100%;
      width: 100%;
      overflow: hidden;
      position: relative; }

    .img-container img {
      z-index: 2;
      margin: 0 auto;
      display: block;
      max-width: 100%;
      max-height: 100%; }

    .img-container button {
      z-index: 99;
      position: absolute;
      right: 15px; }
      .img-container button:not(:disabled) {
        cursor: pointer; }

    .img-container > button:nth-of-type(1):not(#ngx-fs-btn) {
      bottom: 15px; }

    .img-container > button:nth-of-type(2):not(#ngx-fs-btn) {
      bottom: 65px; }

    .img-container > button:nth-of-type(3):not(#ngx-fs-btn) {
      bottom: 115px; }

    .img-container > button:nth-of-type(4):not(#ngx-fs-btn) {
      bottom: 165px; }

    .img-container > button:nth-of-type(5):not(#ngx-fs-btn) {
      bottom: 215px; }

    .img-container > button:nth-of-type(6):not(#ngx-fs-btn) {
      bottom: 265px; }

    .img-container > button:nth-of-type(7):not(#ngx-fs-btn) {
      bottom: 315px; }

    #ngx-fs-btn {
      top: 15px; }

    button.default {
      height: 40px;
      width: 40px;
      border: 1px solid #555;
      border-radius: 50%;
      background-color: white;
      opacity: 0.7;
      -webkit-transition: opacity 200ms;
      transition: opacity 200ms; }

    button.default:hover {
      opacity: 1; }

    button.default:disabled {
      opacity: 0.25; }

    .nav-button-container > button {
      position: relative;
      right: 0;
      margin: 0 10px; }

    .nav-button-container {
      text-align: center;
      position: absolute;
      z-index: 98;
      bottom: 10px;
      left: 0;
      right: 0; }

    .spinner-container {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 60px;
      height: 60px;
      margin: auto;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 25%; }

    .spinner {
      border-width: 7px;
      border-style: solid;
      border-color: #ccc;
      border-bottom-color: #222;
      border-radius: 50%;
      height: 100%;
      width: 100%;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      -webkit-animation: rotation 2s linear infinite;
      /* Safari 4+ */
      /* Fx 5+ */
      /* Opera 12+ */
      animation: rotation 2s linear infinite;
      /* IE 10+, Fx 29+ */ }

    @keyframes rotation {
      from {
        -webkit-transform: rotate(0deg); }
      to {
        -webkit-transform: rotate(359deg); } }

    @-webkit-keyframes rotation {
      from {
        -webkit-transform: rotate(0deg); }
      to {
        -webkit-transform: rotate(359deg); } }
  `]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: undefined, decorators: [{ type: Optional }, { type: Inject, args: ['config', ] }, ]},
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'src': [{ type: Input },],
'index': [{ type: Input },],
'config': [{ type: Input },],
'indexChange': [{ type: Output },],
'configChange': [{ type: Output },],
'customEvent': [{ type: Output },],
'nextImage': [{ type: HostListener, args: ['window:keyup.ArrowRight', ['$event'], ] },],
'prevImage': [{ type: HostListener, args: ['window:keyup.ArrowLeft', ['$event'], ] },],
'onMouseOver': [{ type: HostListener, args: ['mouseover', ] },],
'onMouseLeave': [{ type: HostListener, args: ['mouseleave', ] },],
};
}

function ImageViewerComponent_tsickle_Closure_declarations() {
/** @type {?} */
ImageViewerComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ImageViewerComponent.ctorParameters;
/** @type {?} */
ImageViewerComponent.propDecorators;
/** @type {?} */
ImageViewerComponent.prototype.src;
/** @type {?} */
ImageViewerComponent.prototype.index;
/** @type {?} */
ImageViewerComponent.prototype.config;
/** @type {?} */
ImageViewerComponent.prototype.indexChange;
/** @type {?} */
ImageViewerComponent.prototype.configChange;
/** @type {?} */
ImageViewerComponent.prototype.customEvent;
/** @type {?} */
ImageViewerComponent.prototype.style;
/** @type {?} */
ImageViewerComponent.prototype.fullscreen;
/** @type {?} */
ImageViewerComponent.prototype.loading;
/** @type {?} */
ImageViewerComponent.prototype.scale;
/** @type {?} */
ImageViewerComponent.prototype.rotation;
/** @type {?} */
ImageViewerComponent.prototype.translateX;
/** @type {?} */
ImageViewerComponent.prototype.translateY;
/** @type {?} */
ImageViewerComponent.prototype.prevX;
/** @type {?} */
ImageViewerComponent.prototype.prevY;
/** @type {?} */
ImageViewerComponent.prototype.hovered;
/** @type {?} */
ImageViewerComponent.prototype.moduleConfig;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageViewerComponent } from './image-viewer.component';
import { ImageViewerConfig } from './image-viewer-config.model';
import { ToggleFullscreenDirective } from './fullscreen.directive';
export class ImageViewerModule {
/**
 * @param {?=} config
 * @return {?}
 */
static forRoot(config?: ImageViewerConfig): ModuleWithProviders {
    return {
      ngModule: ImageViewerModule,
      providers: [{provide: 'config', useValue: config}]
    };
  }
static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
  imports: [
    CommonModule
  ],
  declarations: [
    ImageViewerComponent,
    ToggleFullscreenDirective
  ],
  exports: [
    ImageViewerComponent,
    ToggleFullscreenDirective
  ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function ImageViewerModule_tsickle_Closure_declarations() {
/** @type {?} */
ImageViewerModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ImageViewerModule.ctorParameters;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

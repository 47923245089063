import { Directive, HostListener, OnChanges, Input, ElementRef } from '@angular/core';
import * as screenfull from 'screenfull';
export class ToggleFullscreenDirective implements OnChanges {

    
    isFullscreen: boolean;
/**
 * @param {?} el
 */
constructor(private el: ElementRef) { }
/**
 * @return {?}
 */
ngOnChanges() {
        if (this.isFullscreen && screenfull.enabled) {
            screenfull.request(this.el.nativeElement);
        } else if (screenfull.enabled) {
            screenfull.exit();
        }
    }

static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
    selector: '[ngxToggleFullscreen]'
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'isFullscreen': [{ type: Input, args: ['ngxToggleFullscreen', ] },],
};
}

function ToggleFullscreenDirective_tsickle_Closure_declarations() {
/** @type {?} */
ToggleFullscreenDirective.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ToggleFullscreenDirective.ctorParameters;
/** @type {?} */
ToggleFullscreenDirective.propDecorators;
/** @type {?} */
ToggleFullscreenDirective.prototype.isFullscreen;
/** @type {?} */
ToggleFullscreenDirective.prototype.el;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
